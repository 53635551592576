import React from 'react'
import MetaData from "../components/MetaData";
import {Layout} from "antd";
import './404.less'
import Link from "../components/Link";

const NotFoundPage = () => {
    return (
        <div className="app">
            <MetaData
                title="404 Page Not Found | Snowdonia 360"
            />
            <link href="https://fonts.googleapis.com/css?family=Nunito:300,400,600,700&display=swap" rel="stylesheet"></link>
            <Layout className="layout page-404">
                <span />
                <div class="con-404">
                    <div class="framed-con">
                        <div className="frame-con-top">
                            <span class="no">404</span>
                            <span class="message-en">Page Not Found</span>
                            <span class="message-cy">Tudalen Heb ei Darganfod</span>
                        </div>
                        <div className="frame-con-bottom">
                            <Link to="/" className="btn btn-primary btn-small">
                                <span>Home</span>
                                <span className="icon arrow" />
                            </Link>
                            <Link to="/cy/" className="btn btn-primary btn-small">
                                <span>Hafan</span>
                                <span className="icon arrow" />
                            </Link>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default (NotFoundPage);
